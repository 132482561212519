import './components/toastify';
import 'bootstrap';

import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import SmoothScroll from 'smooth-scroll';

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

var swiper = new Swiper('.swiper', {
    modules: [Pagination],
    

    centeredSlides: true,
    pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
        clickable:true,
    },

    breakpoints: {

        0: {
            slidesPerView: 1,
            spaceBetween: 20,
        },

        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },

        992: {
            slidesPerView: 4,
            spaceBetween: 15,
        },
    },
});

var swiperWrapper = document.querySelector('.swiper-wrapper');
if (swiperWrapper) {
  swiperWrapper.style.transform = 'translate3d(0px, 0px, 0px)';
};

/* STTISTICS NUMBER COUNTING */

function startCounting(counters) {
    counters.forEach(counter => {
        let target = +counter.getAttribute('data-target');
        let count = 0;
        let step = Math.ceil(target / 50);

        let counts = setInterval(function () {
            count += step;
            counter.textContent = count;

            if (count >= target) {
                clearInterval(counts);
                counter.textContent = target;
            }
        }, 20);
    });
}

document.addEventListener('DOMContentLoaded', function () {
    let counters = document.querySelectorAll('.counter');


    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {

                startCounting(counters);
                observer.unobserve(entry.target);
            }
        });
    });


    const section = document.querySelector('.statistics');
    observer.observe(section);
});


/* LIGHTBOX */

const lightbox = document.getElementById('lightbox');
const lightboxImage = document.getElementById('lightbox-image');
const closeLightboxBtn = document.getElementById('close-lightbox');

const lightboxTriggers = document.querySelectorAll('.lightbox-trigger');

lightboxTriggers.forEach((trigger, index) => {
    trigger.addEventListener('click', (e) => {
        e.preventDefault();
        openLightbox(index);
    });
});

closeLightboxBtn.addEventListener('click', (e) => {
    e.preventDefault();
    closeLightbox();
});

function openLightbox(index) {
    const imageUrl = document.querySelectorAll('.lightbox-trigger')[index].src;
    lightboxImage.src = imageUrl;
    lightbox.style.display = 'flex';
}

function closeLightbox() {
    lightbox.style.display = 'none';
    lightboxImage.src = '';
}